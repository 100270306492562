<template>
  <div class="newTask">
    <div class="task-menu">
      <Menu ref="menuRef" @on-Click="showList" :iconList="list"/>
    </div>
    <DemandList v-if="showListType === 3" @closeDemand="closeDemand"/>
    <DemandSheetPreparation v-if="showListType === 1" @closeDemand="closeDemand"/>
  </div>
</template>

<script>
import DemandList from './DemandApproval/index.vue'
import DemandSheetPreparation from './demand-sheet-preparation/index.vue' // 需求单定制
import Menu from "@/components/newmenu/menu"
import CesiumLayer from "@/components/cesium-layer";
import FiexdRight from '@/components/fiexdright'
export default {
  components: {
    Menu, CesiumLayer, FiexdRight , DemandList, DemandSheetPreparation
  },
  data() {
    return {
       // isShow: false,
      list: 
      [
        // {
        //   id: 1,
        //   name: '需求单制定',
        //   class: 'icon-dimanxiaodengjishu'
        // },
        // {
        //   id: 2,
        //   name: '日历排班',
        //   class: 'icon-youxiao'
        // },
        // {
        //   id: 3,
        //   name: '需求单审批',
        //   class: 'icon-shenpi'
        // }
      ],
      showListType:1 // 控制展示左侧哪个导航
    }
  },
  methods:{
    showList(item){
      console.log(item);
      this.showListType = item.id
    },
    closeDemand(){
      this.showListType=false
    },
        init_jurisdiction(){
      const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:tasks:maketask") > -1) {
      this.list.push({
          id: 1,
          name: '需求单制定',
          class: 'icon-dimanxiaodengjishu'
        })
    }
    if (permissionList.indexOf("hn:tasks:applytask") > -1) {
      this.list.push({
          id: 3,
          name: '需求单审批',
          class: 'icon-shenpi'
        })
    }
    console.log(this.list);
    this.showListType = this.list[0]?.id
    }
  },
  created(){
    this.init_jurisdiction()
  },
  mounted(){
    if (this.list.length) this.$refs.menuRef.select(this.list[0])
  }
}
</script>

<style lang="scss" scoped>
  .newTask {
    .task-menu {
      position: absolute;
      left: 0;
      z-index: 1;
      width: 108px;
      height: 100%;
    }
    .task-body {
      width: 100%;
      height: 100%;
      flex: 1;
      background: #892148;
    }
  }
</style>