<template>
  <div>

    <!-- 需求单制定 -->
    <BigDialog v-if="mapSelectionArea" @on-close="cancelSaveArea" title="区域选择" class="area-select-popup" :top='122'
      :width='1600'>
      <div>
        <div class="content">
          <div class="button">
            <el-button @click="cancelSaveArea" type="primary">取消</el-button>
            <el-button @click="saveArea" type="primary">保存</el-button>
          </div>
          <Drawky :leftOnclick="(value) => leftOnclick(value)" :rigthOnclick="(value) => rigthOnclick(value)"
            :move="(value) => move(value)" :airspcelist="areaPointList" :drakyStyle="true"
            :addallairArray="(value) => addallairArray(value)" />
        </div>
      </div>
    </BigDialog>

    <!-- 详情弹框 -->
    <Details v-if="Details_List" @on-closeDetails="closeDetails" :Details_List="Details_List" />
    <!-- 需求单制定弹框 -->
    <Dialog v-if="initiatingDemandPopupIsShow" @on-close="closeIDsPopup" title="需求单制定" :width="1510" :top="120"
      :left="120">
      <div class="content hwb-flex">
        <!-- 左侧发起需求 -->
        <div class="create-need">
          <!-- 发起需求弹框 -->
          <!-- :hide-required-asterisk="true"  表单清除红色提示-->
          <el-form ref="edit_form" :model="edit_form" :label-position="'left'" :rules="rules" class="edit-form"
            label-width="110px">
            <el-form-item label="需求单名称" prop="taskTitle">
              <el-input v-model="edit_form.taskTitle" placeholder="请输入需求单名称" maxlength="254" clearable />
            </el-form-item>
            <el-form-item label="任务类型" prop="taskCateId">
              <el-select v-model="edit_form.taskCateId" placeholder="请选择任务类型" clearable :disabled="updateNeedData">
                <el-option v-for="item in drd.task_type" :key="item.id" :label="item.title" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="任务场景" prop="taskTypeId">
              <el-select v-model="edit_form.taskTypeId" placeholder="请选择场景类型" clearable>
                <el-option v-for="item in drd.task_cate" :key="item.id" :label="item.title" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="时间要求" required>
              <div class="timecss">
                <el-form-item prop="startedAt" style="margin-bottom: 0px;">
                  <el-date-picker v-model="edit_form.startedAt" :picker-options="pickerOptionsStart"
                    @focus="dateStartPickerFocus" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="开始日期"
                    clearable>
                  </el-date-picker>
                </el-form-item>
                <span style="width: 6px; color: #D8D8D8; text-align: center">-</span>
                <el-form-item prop="endAt" style="margin-bottom: 0px;">
                  <el-date-picker v-model="edit_form.endAt" :picker-options="pickerOptionsEnd" @focus="dateEndPickerFocus"
                    value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="结束日期" clearable>
                  </el-date-picker>
                </el-form-item>
              </div>
            </el-form-item>

            <el-form-item label="任务目标位置" prop="destination">
              <el-cascader class="input-orgname" placeholder="请选择街道" v-model="edit_form.destination"
                :options="drd.cityType" :props="{
                  children: 'children',
                  label: 'title',
                  value: 'id',
                  emitPath: false,
                  empty: false,
                }" clearable>
              </el-cascader>
            </el-form-item>
            <el-form-item label="详细位置说明" prop="origin">
              <el-input v-model="edit_form.origin" placeholder="请输入详细位置" clearable maxlength="254" />
            </el-form-item>
            <el-form-item label="区域选择">
              <span @click="mapSelectionArea = true" :class="[areaPointList ? 'selected-area ' : 'noselected-area']">
                {{ areaPointList ? "(点击修改区域)" : "(点击选择区域)" }}
              </span>
            </el-form-item>
            <el-form-item label="需求描述" style="width: 100%" prop="remark">
              <el-input v-model="edit_form.remark" placeholder="请输入需求描述" type="textarea" :rows="4" clearable
                maxlength="254"></el-input>
            </el-form-item>
          </el-form>
          <!-- 确定按钮 -->
          <div class="anniu">
            <div @click="cancelUpdate" v-if="updateNeedData" class="button button2">取消修改</div>
            <div @click="handle_save" class="button">{{ updateNeedData ? '确认修改' : '确认' }}</div>
          </div>
        </div>
        <!-- 右侧列表 -->
        <div class="list">
          <!-- 顶部四个东西 -->
          <div class="approval-info hwb-flex">
            <!-- 待审批 -->
            <div @click="clickTask(0)" class="jc hwb-flex  dsp" :class="Status == 0 ? 'selected' : ''"
              style="color:#50F9F4;">
              <div class="jc-tp">
                <img src="./img/dsp.svg" alt="">
              </div>
              <div class="jc-text">
                <div class="num">{{ approvalStatus.wait }}</div>
                <div class="text">待审批</div>
              </div>
            </div>
            <!-- 审批通过 -->
            <div @click="clickTask(1)" class="jc hwb-flex  sptg" :class="Status == 1 ? 'selected' : ''"
              style="color:#00C4FF;">
              <div class="jc-tp">
                <img src="./img/sptg.svg" alt="">
              </div>
              <div class="jc-text">
                <div class="num">{{ approvalStatus.pass }}</div>
                <div class="text">审批通过</div>
              </div>
            </div>
            <!-- 默认通过 -->
            <div @click="clickTask(3)" class="jc hwb-flex  mrtg" :class="Status == 3 ? 'selected' : ''"
              style="color:#3AFF95;">
              <div class="jc-tp" style="padding-bottom:20px">
                <img src="./img/mrtg.svg" alt="">
              </div>
              <div class="jc-text">
                <div class="num">{{ approvalStatus.def }}</div>
                <div class="text">默认通过</div>
              </div>
            </div>
            <!-- 审批不通过 -->
            <div @click="clickTask(2)" class="jc hwb-flex  spbtg" :class="Status == 2 ? 'selected' : ''"
              style="color:#FFD360;">
              <div class="jc-tp">
                <img src="./img/spbtg.svg" alt="">
              </div>
              <div class="jc-text">
                <div class="num">{{ approvalStatus.notPass }}</div>
                <div class="text">审批不通过</div>
              </div>
            </div>
          </div>
          <!-- 表格 -->
          <Table class="task-table" :columns="columns" :dataSource="dataSource" :pagination="pagination" @size-change="
            (size) => {
              pagination.pageSize = size;
              async_get_list();
            }
          " @page-change="
  (page) => {
    pagination.page = page;
    async_get_list();
  }
">
            <template #taskCateName="{ row }">
              <div class="action" :style="`color:${row.taskCateName == '常态任务' ? '#6AFF64' : '#FF3636'}`">
                {{ row.taskCateName }}
              </div>
            </template>
            <template #papplyStatus="{ row }">
              <div class="action" :style="getApplyColor(row.papplyStatus)">
                {{ map.apply[row.papplyStatus] }}
              </div>
            </template>
            <template #disposeStatus="{ row }">
              <div class="action" :style="row.disposeStatus === 1 ? 'color:#FFBD36' : 'color:#0AFF00'">
                {{ row.papplyStatus === 1 || row.papplyStatus === 3 ? (row.disposeStatus ? row.disposeStatus ===
                  1 ? '待派单' : '已派单' : '') : '' }}
              </div>
            </template>
            <template #taskStatusName="{ row }">
              <div class="action" :style="getExecutionColor(row.taskStatusName)">
                {{ row.taskStatusName }}
              </div>
            </template>
            <template #action="{ row }">
              <div class="action">
                <span title="修改" @click="updateNeed(row)" v-view="{ sign: 'hn:tasks:update' }" v-if="
                  row.applyStatusName == '待审批' &&
                  row.userId == user_info_hn.uid &&
                  row.taskCateName != '临时紧急任务' &&
                  row.taskPApplyList[0] &&
                  !row.taskPApplyList[0].papplyStatus
                " class="iconfont icon-bianji1" style="color:#35D9E7;padding-right:6px"></span>
                <span title="详情" @click="DetailsShow(row)" class="iconfont icon-chakanxiangqing w22 h22"
                  style="color:#35D9E7;padding-right:6px"></span>
                <span v-if="row.taskStatusName == '已完成'" @click="routerTo(row)" title="成果库"
                  class="iconfont icon-renwubaogao2" style="color:#35D9E7"></span>
              </div>
            </template>
          </Table>
        </div>
      </div>
    </Dialog>

  </div>
</template>

<script>
import Dialog from '@/components/common/new-dialog/index-tow.vue'; // 弹框组件
import BigDialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import Table from "@/components/common/table-new-hwb"; // 列表组件
import Drawky from "@/components/drawky"; // 绘制区域的
import Details from "./details.vue"; // 详情弹框
import mqtt from 'mqtt'
import { mapGetters } from "vuex";
import * as turf from '@turf/turf'
import dayjs from "dayjs";
import { transformCartesian2WGS84 } from "@/utils/cesium";
const { VUE_APP_MTQQ } = process.env;
import API from "@/api";
export default {
  components: {
    Dialog,
    Table,
    Drawky,
    Details,
    BigDialog
  },
  data() {
    return {
      client: null,  // 装mqtt的东西  以便销毁
      Details_List: null, // 详情存的数据
      pickerOptionsStart: {}, //预计飞行开始时间限制
      pickerOptionsEnd: {}, //预计飞行结束时间限制
      areaPointList: null, // 发起需求区域绘制点对象
      regionList: null, // 存储选择区域点的原始x y z数据
      mapSelectionArea: false, // 地图选择区域控制弹框显示隐藏
      initiatingDemandPopupIsShow: true, // 控制需求单制定弹框是否展示
      approvalStatus: { // 审批各个状态的数量
        wait: 0,
        pass: 0,
        notPass: 0,
        def: 0,
      },
      Status: null,
      updateNeedData: null, // 如果是修改需求单  吧该需求的数据放在这
      edit_form: {  // 发起需求表单字段
        taskTitle: null,
        taskCateId: 2,
        taskTypeId: null,
        startedAt: null,
        endAt: null,
        origin: null,
        destination: null,
        remark: null,
        locationArea: null, // 存储区域字符串
        flightLineId: null, // 存储航线
      },
      rules: {  // 验证规则
        taskTitle: [
          { required: true, message: "请输入需求单名称", trigger: "blur" },
        ],
        taskCateId: [
          { required: true, message: "请选择任务类型", trigger: "change" },
        ],
        startedAt: [
          { required: true, message: "请选择飞行开始时间", trigger: "blur" },
          { required: true, message: "请选择飞行开始时间", trigger: "change" },
        ],
        endAt: [
          { required: true, message: "请选择飞行结束时间", trigger: "blur" },
          { required: true, message: "请选择飞行结束时间", trigger: "change" },
        ],
        origin: [
          { required: true, message: "请输入详细位置", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入需求描述", trigger: "blur" },
        ],
        taskTypeId: [
          { required: true, message: "请选择场景类型", trigger: "change" },
        ],
        destination: [
          { required: true, message: "请选择街道", trigger: "change" },
        ],
      },
      drd: {
        uav_type: [],
        mount_type: [],
        task_type: [],
        task_cate: [],
        flightLine: [],
        proStatus: [
          { id: 1, label: "待处理", value: 0 },
          { id: 2, label: "处理完成", value: 1 },
          { id: 3, label: "无需处理", value: 2 },
        ],
        cityType: [
          {
            id: 13,
            title: "济源市",
            children: [
              { id: 1, title: "沁园街道" },
              { id: 2, title: "济水街道" },
              { id: 3, title: "北海街道" },
              { id: 4, title: "天坛街道" },
              { id: 5, title: "玉泉街道" },
              { id: 6, title: "克井镇" },
              { id: 7, title: "五龙口镇" },
              { id: 8, title: "轵城镇" },
              { id: 9, title: "承留镇" },
              { id: 10, title: "邵原镇" },
              { id: 11, title: "坡头镇" },
              { id: 12, title: "梨林镇" },
              { id: 17, title: "大峪镇" },
              { id: 14, title: "思礼镇" },
              { id: 15, title: "王屋镇" },
              { id: 16, title: "下冶镇" },


            ],
          },
        ],
      },

      columns: [
        { prop: "index", label: "序号", width: "120px" },
        { prop: "taskTitle", label: "需求单名称", width: "300px" },
        { prop: "taskCateName", slot: true, label: "任务类型", width: "150px" },
        { prop: "taskTypeName", label: "任务场景", width: "200px" },
        { prop: "papplyStatus", slot: true, label: "审批状态", width: "150px" },
        { prop: "disposeStatus", slot: true, label: "调度状态", width: "150px" },
        { prop: "taskStatusName", slot: true, label: "执行状态", width: "150px" },
        { prop: "organizationName", label: "单位名称", width: "250px" },
        { prop: "action", slot: true, label: "操作", width: "200px" },
      ],
      dataSource: [],
      map: {
        status: {
          "-2": "已拒绝",
          "-1": "已取消",
          0: "待执行",
          1: "已完成",
          // 2: "执行中",
          3: "执行中",
        },
        apply: {
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
    ...mapGetters(["dbox_data"])
  },
  methods: {
    async DetailsShow(row) {
      const res = await API.TASK.approveMsgInfo(row.id)
      if (res.status == 1) {
        this.Details_List = res.data
      } else {
        this.$message.error(res.message)
      }
    },
    routerTo(row) {
      this.$router.push({
        name: 'flightManagement',
        params: {
          id: 3,
          taskName: row.taskTitle
        }
      })
    },
    // 初始化mtqq
    initMtqq() {
      // let list = this.onLineList.map(item => 'mmc/warning/implement/'+item)
      const options = {
        connectTimeout: 30000,
        clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
        username: 'admin',
        password: 'mmcaabcE',
        clean: true
      }
      this.client = mqtt.connect(VUE_APP_MTQQ, options)


      if (this.client.connected) {
        this.client.end();
      }

      // mqtt连接
      this.client.on("connect", e => {
        console.log("连接成功:");
        this.client.subscribe('mmc/warning/implement', { qos: 0 }, error => {
          if (!error) {
            console.log("订阅成功");
          } else {
            console.log("订阅失败");
          }
        });
      });
      // 接收消息处理
      this.client.on("message", (topic, message) => {
        // let data = message.toString()
        // let dataList = JSON.parse(data.replaceAll('\\','').replaceAll('"[','[').replaceAll(']"',']'))
        // console.log(data,dataList);
        // console.log('刷新列表');
        this.async_get_list()
      });
      // 断开发起重连
      this.client.on("reconnect", error => {
        console.log("正在重连:", error);
      });
      // 链接异常处理
      this.client.on("error", error => {
        console.log("连接失败:", error);
      });
    },



    closeDetails() {
      this.Details_List = null
    },
    // -------------------------------------------------------------------------------时间选择限制开始
    dateStartPickerFocus() {
      // 预计飞行开始时间获取焦点做选择限制
      this.pickerOptionsStart = {
        disabledDate: (time) => {
          // 日期选择限制
          return time.getTime() < Date.now() - 8.64e7;
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    dataFilter() {
      if (this.edit_form.startedAt || this.edit_form.endAt) {
        if (this.edit_form.startedAt == this.edit_form.endAt) {
          console.log(20221113);
          return true
        }
      }

    },
    dateEndPickerFocus() {
      // 预计飞行结束时间获取焦点做选择限制
      this.pickerOptionsEnd = {
        disabledDate: (time) => {
          // 日期选择限制
          if (
            this.edit_form.startedAt &&
            new Date(this.edit_form.startedAt).getTime() > Date.now()
          ) {
            return (
              time.getTime() < new Date(this.edit_form.startedAt).getTime() - 8.64e6
            );
          } else {
            return time.getTime() < Date.now() - 8.64e6;
          }
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    getNowTime(val) {
      // 时间时分秒限制
      let date;
      if (val && new Date(val).getTime() > Date.now()) {
        date = new Date(val);
      } else {
        date = new Date();
      }
      let hours = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hours < 10) hours = "0" + hours;
      if (minute < 10) minute = "0" + minute;
      if (second < 10) second = "0" + second;
      let timeStr = `${hours}:${minute}:${second}`;
      return timeStr;
    },
    // ---------------------------------------------------------------------发起需求函数开始
    cancelUpdate() { // 取消修改按钮
      this.updateNeedData = null
      this.edit_form = {
        taskTitle: null,
        taskCateId: 2,
        taskTypeId: null,
        startedAt: null,
        endAt: null,
        origin: null,
        destination: null,
        remark: null,
        locationArea: null, // 存储区域字符串
        flightLineId: null, // 存储航线
      }
      this.areaPointList = null
      this.$refs.edit_form.resetFields();
    },
    updateNeed(item) { // 修改需求按钮
      console.log(item);
      this.updateNeedData = item
      let destination
      this.drd.cityType[0].children.filter(i => {
        if ('济源市' + i.title == item.destination || i.title == item.destination) {
          destination = i.id
        }
      })
      this.edit_form = {
        taskTitle: item.taskTitle,
        taskCateId: item.taskCateId,
        taskTypeId: item.taskTypeId,
        startedAt: item.startedAt,
        endAt: item.endAt,
        origin: item.origin,
        destination,
        remark: item.remark,
        locationArea: item.locationArea, // 存储区域字符串
        flightLineId: item.flightLineId, // 存储航线
      }
      if (item.locationArea) {  // 如果绘制了空域
        this.areaPointList = { regionList: null };
        let araeArr = [];
        araeArr = item.locationArea.split(" ");
        let lastAraeArr = [];
        araeArr.forEach((item) => {
          let areaPoint = item.split(",");
          lastAraeArr.push(areaPoint[0] * 1);
          lastAraeArr.push(areaPoint[1] * 1);
        });
        this.areaPointList.regionList = lastAraeArr;
      }
    },
    async handle_save() {  // 发起需求确定按钮
      this.$refs["edit_form"].validate(async (valid) => {
        if (valid) {
          let dataBoolean = this.dataFilter()
          if (dataBoolean) return this.$message.warning('时间不能相同')
          if (this.areaPointList && this.areaPointList.regionList.length > 2) {
            // 画了区域的话转一下格式
            let regionListCopy = this.areaPointList.regionList.concat();
            let first = regionListCopy[0],
              last = regionListCopy[regionListCopy.length - 1];
            regionListCopy.shift();
            regionListCopy.pop();
            let fv = [];
            let asx = this.oneArrToTwoArr(regionListCopy);
            asx.map((item) => fv.push([item[0], item[1]].join(" ")));
            let gg = fv.toString();
            let yg = first + "," + gg + "," + last;
            this.edit_form.locationArea = yg;
          }

          let { destination, startedAt, endAt, ...rest } = this.edit_form;

          const params = {
            ...rest,
            startedAt: dayjs(startedAt).format("YYYY-MM-DD HH:mm:ss"),
            endAt: dayjs(endAt).format("YYYY-MM-DD HH:mm:ss"),
            type: this.Inspection ? 1 : null,
          };
          //下拉框id转换街道
          destination = this.cityTypeIdFile(destination);
          params.destination = destination;
          let res
          if (this.updateNeedData) {
            params.id = this.updateNeedData.id
            res = await API.TASK.EditNew(params)
          } else {
            res = await API.TASK.AddNew(params)
          }
          if (res.status === 1) {
            this.$message.success('创建需求成功')
            this.edit_form = {
              taskTitle: null,
              taskCateId: 2,
              taskTypeId: null,
              startedAt: null,
              endAt: null,
              origin: null,
              destination: null,
              remark: null,
              locationArea: null, // 存储区域字符串
              flightLineId: null, // 存储航线
            }
            this.areaPointList = null
            this.updateNeedData = null
            this.async_get_list()
            this.$refs.edit_form.resetFields();
          } else {
            this.$message.error(res.message)
          }
        }
      });
    },
    cityTypeIdFile(val) {
      if (val == 13) {
        return "济源市";
      } else {
        //筛选出选择的街道
        return this.drd.cityType[0].children.filter(
          (v) => this.edit_form.destination == v.id
        )[0].title;
      }
    },
    clickTask(num) {
      this.Status != num ? this.Status = num : this.Status = null;
      this.pagination.page = 1
      this.async_get_list()
    },
    // -------------------------------------------------------------------------发起需求函数结束
    async async_get_list(num) { // 获取列表数据  审批的状态 0：待审批  1：审批通过 2：审批通过 3：默认通过
      const { map } = this;
      const { page, pageSize } = this.pagination;
      let res = await API.TASK.ListNew({
        applyStatus: num || this.Status,
        pageNum: page,
        pageSize: pageSize,
      });
      res = res.data;
      this.dataSource = res.data.task || [];
      this.dataSource = this.dataSource.map((item, index) => ({
        ...item,
        index: (page - 1) * pageSize + index + 1,
        taskStatusName: map.status[item.taskStatus],
        applyStatusName: map.apply[item.papplyStatus],
        processingStatusName: map.processing[item.processingStatus],
      }));
      this.approvalStatus = {
        wait: res.data.wait,
        pass: res.data.pass,
        notPass: res.data.notPass,
        def: res.data.def,
      }
      this.pagination.page = res.pageNo;
      this.pagination.total = res.totalCount;
    },
    closeIDsPopup() { // 关闭弹框
      this.$emit("closeDemand");
    },
    getExecutionColor(status) { // 执行状态颜色
      switch (status) {
        case "待执行":
          return "color: #16D979";
          break;
        case "执行中":
          return "color: #FB8016";
          break;
        case "已完成":
          return "color: #009AFF";
          break;
        case "已拒绝":
          return "color: #F55252"
        default:
          break;
      }
    },
    getApplyColor(status) { //审批状态颜色
      switch (status) {
        case 0:
          return "color: #FFBD36";
          break;
        case 1:
          return "color: #4799F3";
          break;
        case 3:
          return "color: #4799F3";
          break;
        case 2:
          return "color: #FF3636";
          break;
        default:
          break;
      }
    },
    //-------------------------------------------------------------------------------绘制区域函数
    leftOnclick(data) {
      // if (data.index === -1) {
      this.regionList = data.positions;
      // }
    },
    rigthOnclick(data) {
      console.log(data, '-----');
      if (data && data.positions && data.positions.type === 1) {
      } else {
        this.regionList = data.positions;
      }
    },
    move(data) {
      this.regionList = data.positions;
    },
    cleartooltip() {
      let dom = document.querySelectorAll('.cesium-tooltip')
      dom = [].slice.apply(dom)
      for (let index = 0; index < dom.length; index++) {
        let element = dom[index];
        if (element !== null) {
          element.parentNode.removeChild(element)
        }
      }
    },
    saveArea() {
      this.cleartooltip()
      // 绘制区域弹框点击保存
      if (this.regionList && this.regionList.length > 2) {
        this.areaPointList = { regionList: [] };
        let wsg84 = [];
        this.regionList.map((item) =>
          wsg84.push(transformCartesian2WGS84(item))
        );
        wsg84.map((item) =>
          this.areaPointList.regionList.push(item.lng, item.lat)
        );

        // 将绘制区域经纬度转成符合比较的多维数组
        let newData = [];
        for (let i = 0; i < Math.ceil(this.areaPointList.regionList.length / 2); i++) {
          newData[i] = [];
          newData[i].push(this.areaPointList.regionList[i * 2]);
          newData[i].push(this.areaPointList.regionList[i * 2 + 1]);
        }
        newData.push(newData[0])

        // 将所有禁飞区转成符合比较的多维数组
        let allDate = [];
        this.allairArray.map(item => {
          let mapList = [];
          for (let i = 0; i < Math.ceil(item.length / 2); i++) {
            mapList[i] = [];
            mapList[i].push(item[i * 2]);
            mapList[i].push(item[i * 2 + 1]);
            mapList.push(mapList[0])
          }
          allDate.push(mapList)
        })

        // 比较绘制区域是否与所有禁飞区域重叠
        // let poly1 = turf.polygon([newData])
        // let poly2 = turf.polygon(allDate)

        let box = []
        allDate.forEach(v => { //验证绘画的点是否在多边形内
          newData.forEach(value => {
            let pt = turf.point(value);
            let poly = turf.polygon([v])
            box.push(turf.booleanPointInPolygon(pt, poly))
          })
        })
        if (box.some(v => v == true)) {

          this.areaPointList = null
          this.$message.warning('禁飞区内禁止绘制任务区域')
        } else {
          this.mapSelectionArea = false;
        }
      } else {
        this.$message.warning("请先绘制完区域");
      }
    },
    oneArrToTwoArr(data) {
      let newData = [];
      for (let i = 0; i < Math.ceil(data.length / 2); i++) {
        newData[i] = [];
        newData[i].push(data[i * 2]);
        newData[i].push(data[i * 2 + 1]);
      }
      return newData;
    },
    cancelSaveArea() { // 取消保存
      this.mapSelectionArea = false;
      console.log('取消保存', '取消保存');
    },
    addallairArray(value) { // 将子组件获取的禁飞区传递出来
      this.allairArray = value
    },
    // =--------------------------------------------------------------------------------------------区域结束
    async drd_task_type() { //获取任务类型下拉
      try {
        let res = await API.TASK.GetDrdType();
        res = res.data;
        this.drd.task_type = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async drd_task_cate() { // 任务场景下拉
      try {
        let res = await API.TASK.GetDrdCate();
        res = res.data;
        this.drd.task_cate = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
  },
  watch: {
    "edit_form.startedAt"(val) {
      // 监视预计飞行开始时间做的某些事
      if (val) {
        const date = new Date(new Date(val).setHours(0, 0, 0, 0)).getTime();
        const today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        if (date > today) {
          // 如果选的日期不是和当天同一天
          this.pickerOptionsStart.selectableRange = "00:00:00 - 23:59:59";
        } else {
          // 如果是同一天
          this.pickerOptionsStart.selectableRange = `${this.getNowTime()} - 23:59:59`;
        }
        if (
          new Date(this.edit_form.startedAt).getHours() != new Date().getHours() &&
          new Date(this.edit_form.startedAt).getDay() == new Date().getDay() &&
          new Date(this.edit_form.startedAt).getDate() == new Date().getDate() &&
          new Date(this.edit_form.startedAt).getHours() == 0
        ) {
          this.edit_form.startedAt = new Date();
        }
      }
      if (
        this.edit_form.endAt &&
        val &&
        new Date(val).getTime() > new Date(this.edit_form.endAt).getTime()
      ) {
        this.edit_form.endAt = null; // 如果大于结束时间，清空结束时间
      }
    },
    "edit_form.endAt"(val) {
      if (val && new Date(val).getTime() < new Date(this.edit_form.startedAt).getTime()) {
        this.edit_form.endAt = this.edit_form.startedAt
        return
      }
      if (val && new Date(val).getTime() < new Date().getTime()) {
        this.edit_form.endAt = new Date()
        return
      }
      if (val) {
        const date = new Date(new Date(val).setHours(0, 0, 0, 0)).getTime();
        let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        if (
          this.edit_form.startedAt &&
          new Date(this.edit_form.startedAt).getTime() > Date.now()
        ) {
          today = new Date(
            new Date(this.edit_form.startedAt).setHours(0, 0, 0, 0)
          ).getTime();
        }
        if (date > today) {
          // 如果选的日期不是和开始时间或是当天同一天
          this.pickerOptionsEnd.selectableRange = "00:00:00 - 23:59:59";
        } else {
          // 如果是同一天
          this.pickerOptionsEnd.selectableRange = `${this.getNowTime(
            this.edit_form.startedAt ? this.edit_form.startedAt : ''
          )} - 23:59:59`;
        }
      }
    },
  },
  mounted() {
    this.async_get_list()
    this.drd_task_type()
    this.drd_task_cate()
    this.initMtqq()
  },
  destroyed() {
    if (this.client) {
      this.client.end()
      this.client = null
    }
  }
};
</script>

<style lang="scss" scoped>
/* 绘制区域 */
.area-select-popup {
  // position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  // background: rgba(12, 34, 67, 0.4);
  // z-index: 7000;
  .content {
    // position: absolute;
    // width: 1600px;
    height: 71vh;
    // left: 50%;
    // top: 100px;
    // margin-left: -800px;
    border: 1px solid #009aff;

    .button {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 160px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1000;

      .el-button--primary {
        background-color: #69b3e5;
      }
    }
  }
}

.content {
  /* 左侧 */
  .create-need {
    width: 548px;
    height: 790px;
    position: relative;

    .anniu {
      margin-top: 40px;
      text-align: center;
      justify-content: center;
      display: flex;

      .button {
        width: 126px;
        height: 40px;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 40px;
        background: rgba(38, 71, 238, 0.71);
        box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
        border-radius: 6px;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
        cursor: pointer;
      }

      .button2 {
        margin-right: 30px;
      }
    }

    .dec {
      position: absolute;
      top: -28px;
      height: 848px;
      left: 560px;
      width: 0px;
      border-right: 1px solid #73A6D1;
      box-shadow: 0px 0px 5px #73A6D1;
    }

    .timecss {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ::v-deep {
      .edit-form {
        padding: 22px 20px 0 6px;

        .el-form-item__label {
          height: 36px;
          color: #AAB7C3;
          font-size: 14px;
          text-align: left;
        }

        .el-date-editor--datetime {
          width: 204px;
        }

        .el-input__suffix {
          height: 34px;

          .el-input__suffix-inner {

            .el-input__icon,
            .el-input__clear {
              line-height: 36px;
            }
          }
        }

        .el-cascader {
          width: 100%;
        }

        .el-input {
          box-sizing: border-box;

          .el-input__inner {
            height: 36px;
            box-sizing: border-box;
            background: rgba(40, 53, 122, 0.1);
            border: 1px solid #73A6D1;
            box-shadow: 0px 0px 2px #73A6D1;
            border-radius: 0;
            font-size: 16px;
            color: #9fc4ff;
            letter-spacing: 0;
            font-weight: 400;
            line-height: 36px;
          }

          .el-input__suffix {
            .el-input__suffix-inner {

              .el-input__icon,
              .el-input__clear {
                line-height: 36px;
              }
            }
          }
        }

        .el-textarea {
          textarea {
            box-sizing: border-box;
            background: rgba(40, 53, 122, 0.1);
            background: transparent;
            border: 1px solid #73A6D1;
            box-shadow: 0px 0px 2px #73A6D1;
            border-radius: 0;
            font-size: 16px;
            color: #9fc4ff;
            letter-spacing: 0;
            font-weight: 400;
          }
        }

        .el-form-item__content {


          .el-select {
            width: 100%;
          }
        }







        .linear-fields {
          border: none;
        }

        .overflow-auto {
          overflow: auto;
          max-height: 130px;
          border: 1px solid #27526e;
        }

        .noselected-area {
          color: #999;
          cursor: pointer;
          line-height: 34px;
          font-size: 16px;
        }

        .selected-area {
          color: rgb(25, 194, 107);
          cursor: pointer;
          line-height: 34px;
          font-size: 16px;
        }

        .Inspection {
          height: 37px;
        }
      }
    }
  }

  /* 右侧 */
  .list {
    width: 886px;
    height: 790px;

    .approval-info {
      height: 109px;
      margin-top: 22px;

      .dsp {
        background-image: url(./img/1.png);

        &.selected {
          background-image: url(~@/assets/img/click-3.png);
        }
      }

      .sptg {
        background-image: url(./img/2.png);

        &.selected {
          background-image: url(~@/assets/img/click-1.png);
        }
      }

      .mrtg {
        background-image: url(./img/3.png);

        &.selected {
          background-image: url(~@/assets/img/click-4.png);
        }
      }

      .spbtg {
        background-image: url(./img/4.png);

        &.selected {
          background-image: url(~@/assets/img/click-2.png);
        }
      }

      .jc {
        width: 204px;
        height: 109px;
        cursor: pointer;

        .jc-tp {
          width: 46px;
          height: 46px;
          font-size: 46px;
          margin-left: 20px;

          &.iconfont.icon-leijifeihangjiaci {
            background: linear-gradient(180deg, #FFFFFF 0%, #D0FDFC 59%, #50F9F4 100%);
            //将字体图标改为只显示背景在字体上，就完成了字体的渐变
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-leijifeihangshichang {
            background: linear-gradient(180deg, #CBF3FF 0%, #9BE8FF 59%, #00C4FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-leijifeihangjuli {
            background: linear-gradient(180deg, #EFFFF5 0%, #82FFD0 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-leijirenwushuliang {
            background: linear-gradient(180deg, #FFF0C8 0%, #FFCE4F 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .jc-text {
          text-align: center;
          width: 120px;

          .num {
            font-size: 32px;
            font-family: DINAlternate-Bold, DINAlternate;
          }

          .text {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #DAE4FF;
          }
        }
      }
    }

    .select {
      margin: 32px 0 20px 0;

      ::v-deep {
        .el-input__inner {
          background-color: #000;
          border: 0;
        }
      }
    }

    .task-table {
      margin-top: 10px;
      height: 598px;
    }
  }
}
</style>