<template>
  <div>
    <div id="DemandListShow">
      <Dialog v-if="DemandListShow" @on-close="coloseDemandList" title="需求单审批" :width="1497" :left="120" :top="120">
        <div class="DemandList_Box">
          <div class="left_box_top">
            <div v-for="item in list" :key="item.id" @click="isClickBox(item)" class="item_box"
              :class="item.isClick ? 'isSelect' : ''">
              {{ item.name }}
            </div>
          </div>
          <div class="DemandList_Body">
            <div class="Body_Item" v-for="item in dataList" :key="item.id">
              <div class="Item_Label_Box">
                <div class="Label_Box_Value">
                  <div class="Box_Value_Left">需求清单名称</div>
                  <el-tooltip class="item" effect="dark" :content="item.taskTitle" placement="top">
                    <div class="Box_Value_Right onPules">{{item.taskTitle}}</div>
                  </el-tooltip>
                </div>
                <div class="Label_Box_Value">
                  <div class="Box_Value_Left">任务场景</div>
                  <el-tooltip class="item" effect="dark" :content="item.taskTitle" placement="top">
                    <div class="Box_Value_Right onPules">{{item.taskTypeName}}</div>
                  </el-tooltip>
                </div>
                <div class="Label_Box_Value">
                  <div class="Box_Value_Left">时间要求</div>
                  <el-tooltip class="item" effect="dark" :content="item.taskTitle" placement="top">
                    <div class="Box_Value_Right">{{timeFilter(item.startedAt,item.endAt)}}</div>
                  </el-tooltip>
                </div>
                <div class="Label_Box_Value">
                  <div class="Box_Value_Left">目标位置</div>
                  <el-tooltip class="item" effect="dark" :content="item.destination+'-'+item.origin" placement="top">
                    <div class="Box_Value_Right onPules">{{item.destination+'-'+item.origin}}</div>
                  </el-tooltip>
                </div>
                <div class="Item_Top" :class="imgFilter(item)"></div>
                <div class="Item_Top_Right" @click="ApprovalDetails(item.id)">详情</div>
              </div>
            </div>
          </div>
          <div class="DemandList_Footer">
            <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="currentPage || 1"
              layout="total , prev, pager, next, jumper" :total="total || 0" :page-size="size || 9" />
          </div>

        </div>
      </Dialog>
    </div>
    <div id="dialogIsShow">
      <!-- 执行审批 -->
      <BigDialog v-if="dialogIsShow" @on-close="coloseNeedPopup" title="需求单审批" :width="938" :left="491" :top="188">
        <div class="dispatch">
          <div class="need-top-title hwb-flex">
            <div class="left">
              <div class="dec"></div>需求单审批
            </div>
            <div class="right">{{Details_List.addTime}}</div>
          </div>
          <div class="need-bottom">
            <div class="one-info"><span>需求单名称：</span><span style="color:#fff">{{Details_List.taskTitle}}</span></div>
            <div class="one-info"><span>任务类别：</span><span style="color:#6AFF64">{{Details_List.taskCateName}}</span>
            </div>
            <div class="one-info"><span>任务场景：</span><span style="color:#fff">{{Details_List.taskTypeName}}</span></div>

            <div class="one-info"><span>审核状态：</span><span
                :style="getStatusColor.getApplyColor(Details_List.papplyStatus)">{{getStatusColor.getApplyWord(Details_List.papplyStatus)}}</span>
            </div>
            <div class="one-info"><span>调度状态：</span><span
                :style="getStatusColor.getDispatchColor(Details_List.disposeStatus)">{{getStatusColor.getDispatchWord(Details_List.disposeStatus)}}</span>
            </div>
            <div class="one-info"><span>直属上级审批状态：</span><span
                :style="Details_List.taskCateName=='临时紧急任务' ?' color: #4799F3 ' :getStatusColor.getApplyColor(Details_List.papplyStatusByUser)">{{Details_List.taskCateName=='临时紧急任务'
                ? '默认通过': getStatusColor.getApplyWord(Details_List.papplyStatusByUser)}}</span></div>

            <div class="one-info"><span>发起单位：</span><span style="color:#fff">{{Details_List.organizationName}}</span>
            </div>
            <div class="one-info"><span>发起人姓名：</span><span style="color:#fff">{{Details_List.userName}}</span></div>
            <div class="one-info"><span>联系方式：</span><span style="color:#fff">{{Details_List.phone}}</span></div>

            <div class="info-add">
              <div class="one-info"><span>开始时间：</span><span style="color:#fff">{{Details_List.startedAt}}</span></div>
              <div class="one-info"><span>结束时间：</span><span style="color:#fff">{{Details_List.endAt}}</span></div>
              <div class="one-info"><span class="Task_Name">任务区域：</span><span class="Task_Value" :style="Details_List.locationArea ? 'cursor: pointer;color: #265AD5;' : ''" @click="Selection(Details_List)">{{Details_List.locationArea ? '点击查看' : '暂无' }}</span></div>
            </div>

            <div class="one-info-long"><span>任务目标位置：</span><span style="color:#fff">{{Details_List.destination}}</span>
            </div>
            <div class="one-info-long"><span>详细位置说明：</span><span style="color:#fff">{{Details_List.origin}}</span></div>

          </div>
          <div class="one-info-long mb10"><span style="color:#aab7c3">任务航线：</span>
        <span @click="showLline(Details_List)" style="color:#265ad5" class="cp"
          v-if="Details_List.flightLineId">航线预览</span>
        <span class="cf" v-else> 暂无航线 {{ Details_List.flightLineId }}</span>
      </div>
          <div style="color:#AAB7C3">需求单任务内容描述：</div>
          <div class="detail">
            {{Details_List.remark}}
          </div>
          <div style="color:#AAB7C3" v-if="Details_List.papplyStatus===2">审批不通过原因:</div>
          <div class="detail" v-if="Details_List.papplyStatus===2">
            {{Details_List.approvalRemark}}
          </div>
          <div v-if="Details_List.papplyStatus===0 && user_info_hn.departmentId === 60" class="need-task-type hwb-flex">
            <div>需求单是否需要创建子任务：</div>
            <div>
              <el-radio v-model="radio" label="1">是</el-radio>
              <el-radio v-model="radio" label="2">否</el-radio>
            </div>
          </div>
          <!-- 审批按钮 -->
          <div class="button hwb-flex" v-if="Details_List.papplyStatus===0">
            <div class="no" @click="showApprovalFailed">审核不通过</div>
            <div class="yes" @click="submitApproval(1)">审核通过</div>
          </div>
        </div>
      </BigDialog>
    </div>
    <!-- 审批通过后选择单任务或多任务 谈论后放在审批之前确认 -->
    <!-- <div id="Approved">
    <BigDialog  class="Approved" v-if="Approved" @on-close="submitApproval(1,true)" title="审批通过" :width="545" :left="687" :top="358" >
      <div class="Approved_Body">
        <div class="Approved_Body_Item"><i class="iconApproved iconfont icon-biaojiyidu"></i><span class="Item_Label">审核已通过</span></div>
        <div class="Approved_Body_Item"><span class="Item_Label_Select">请选择是否添加多任务</span></div>
        <div class="Approved_Body_Item"><el-radio v-model="radio" label="1">需求单单任务</el-radio></div>
        <div class="Approved_Body_Item"><el-radio v-model="radio" label="2">需求单多任务</el-radio></div>
      </div>
        <div class="Approved_Footer">
          <div class="no" @click="submitApproval(1,true)">取消</div>
          <div class="yes" @click="submitApproval(1)">确定</div>
        </div>
    </BigDialog>
  </div> -->
    <div id="ApprovalFailed">
      <!-- 审批不通过 -->
      <BigDialog v-if="ApprovalFailed" class="Approved" @on-close="coloseApprovalFailed" title="审批不通过" :width="545"
        :left="687" :top="358">
        <div class="Approved_Body FailedTextarea">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入审批不通过原因"
            v-model="ApprovalFailedTextarea">
          </el-input>
        </div>
        <!-- 审批按钮 -->
        <div class="Approved_Footer">
          <div class="no" @click="coloseApprovalFailed">取消</div>
          <div class="yes" @click="submitApproval(2)">确定</div>
        </div>
      </BigDialog>
    </div>
    <RegionalSelection 
    :ShowSelection="ShowSelection" 
    :cancelSaveArea="cancelSaveArea" 
    :addallairArray="addallairArray"
    :leftOnclick="leftOnclick"
    :rigthOnclick="rigthOnclick"
    :move="move"
    :rowList="rowList"
    ></RegionalSelection>
    <div class="out-content h800 pa">
      <el-dialog append-to-body :title="taskshow.list ? '预览航线' : '历史轨迹预览'" :close-on-click-modal="false"
        :visible.sync="taskshow.show" v-if="taskshow.show" :before-close="clearTask" class="taskDialong  ">
        <Draw v-if="taskshow" :taskshow="taskshow.list" :historicalTrack="historicalTrackList" :readonly="taskshow.show"
          class="taskDraw" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import RegionalSelection from '@/components/common/regionalSelection/index.vue'
import Dialog from "@/components/common/new-dialog/index-tow.vue"; // 弹框组件
import BigDialog from "@/components/common/new-dialog/index.vue"; // 弹框组件(有遮罩层)
import { mapGetters } from "vuex";
import Draw from "@/components/draw";
import API from "@/api";
import getStatusColor from "@/api/statusColorGet.js"; // 获取颜色函数
export default {
  components: {
    Dialog, BigDialog,RegionalSelection,Draw
  },
  props: {
  },
  data() {
    return {
      getStatusColor,
      DemandListShow: true,//控制弹出框显示隐藏
      list: [
        {
          id: 0,
          name: "待审批",
          isClick: true,
        },
        {
          id: 1,
          name: "审批通过",
          isClick: false,
        },
        {
          id: 2,
          name: "审批不通过",
          isClick: false,
        },
      ],
      //分页
      currentPage: 1,
      total: 0,
      size: 9,
      dataList: [],//分页数据
      applyStatusId: '0',//初始化的数据以及更改审批状态更新列表
      dialogIsShow: false,//控制需求详情弹框
      ApprovalFailed: false,//控制需求详情内的审批不通过弹框
      radio: "1",//审批的时候确定它是单任务或者多任务
      Details_List: null, //任务数据
      ApprovalFailedTextarea: null, //审批不通过原因
      mapList: { //列表数据过滤
        status: {
          [-1]: "已取消",
          0: "待执行",
          1: "已完成",
          3: "执行中",
        },
        apply: {
          [-1]: "暂无",
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
      },
      taskshow: {
        show: false, // 预览航线是否展示
        list: null, // 预览航线存储数据
      },
             //区域选择
      ShowSelection:false,
      areaPointList: null, // 发起需求区域绘制点对象
      rowList:null,//点击区域任务
    };
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  methods: {
    clearTask(done) {
      this.taskshow.list = null;
      this.taskshow.show = false;
      done && done();
    },
    async showLline(data) {
      if (data) {

        let res = await API.TASK.flightLineInfo(data.flightLineId);

        if (res.status === 1) {
          this.historicalTrackList = [];
          this.taskshow.list = JSON.parse(res.data.flightCourseJson);
          this.taskshow.show = true;
        } else {
          this.$message.error(res.message);
        }
      }
      
    },
        //区域选择
    leftOnclick(data) {
          console.log(data);
    },
    rigthOnclick(data) {
       console.log(data);
    },
    move(data) {
       console.log(data);
    },
    cancelSaveArea(){ //关闭区域选择
      this.rowList = null
      this.ShowSelection = false
    },
    // 将子组件获取的禁飞区传递出来
    addallairArray(value) {
      this.allairArray = value
    },
    Selection(row){
      console.log(row.locationArea);
      if (row.locationArea) {
      this.rowList = row
      this.ShowSelection = true
      }
    },

    async submitApproval(num) { //审批通过与不通过 1通过 2不通过
      let obj = {
        id: this.Details_List.id,
        status: num,
      };
      if (num == 2) {  // 审批不通过
        if (!this.ApprovalFailedTextarea) {
          this.$message.warning("请输入审批不通过原因");
        } else {
          obj.approvalRemark = this.ApprovalFailedTextarea;
          const res = await API.TASK.approvalRequirements(obj);
          if (res.status == 1) {
            this.$message.success("处理成功，该需求审批不通过！");
            this.ApprovalFailed = false
            this.ApprovalFailedTextarea = null
            this.dialogIsShow = false
            this.async_getList()
          } else {
            this.$message.error(res.message);
          }
        }
      } else { // 审批通过
        obj.isSubtasks = this.radio;
        const res = await API.TASK.approvalRequirements(obj);
        if (res.status == 1) {
          this.$message.success("处理成功，该需求审批通过！");
          this.dialogIsShow = false
          this.async_getList()
        } else {
          this.$message.error(res.message);
        }
      }
    },
    coloseApprovalFailed() { //关闭审批不通过弹框
      this.ApprovalFailed = false
      this.ApprovalFailedTextarea = null
    },
    coloseNeedPopup() { //关闭需求详情弹框
      this.dialogIsShow = false
    },
    showApproved() { //需求详情弹框
      this.Approved = true
    },
    showApprovalFailed() { //需求详情弹框
      this.ApprovalFailed = true
    },
    async ApprovalDetails(id) { //需求详情弹框
      const res = await API.TASK.approveMsgInfo(id)
      this.Details_List = res.data
      this.dialogIsShow = true
    },
    imgFilter(item) { //图标筛选（根据类名）
      console.log(item.papplyStatus);
      if (item.papplyStatus == 1 || item.papplyStatus == 3) {
        return 'Approved'
      } else if (item.papplyStatus == 2) {
        return 'ApprovalFailed'
      } else { }
    },
    timeFilter(startedAt, endAt) { //时间筛选
      let start = startedAt.substr(0, startedAt.lastIndexOf(':'))
      let end = endAt.substr(0, endAt.lastIndexOf(':'))
      return `${start}-${end}`
    },
    async onChangePage(page) {//分页切换页码
      this.currentPage = page;
      await this.async_getList();
    },
    coloseDemandList() {//关闭弹框
      this.$emit("closeDemand");

    },
    isClickBox(item) { //左上点击切换列表数据
      this.list.forEach((v) => {
        v.isClick = false;
      });
      item.isClick = true;
      this.list.forEach(async (v) => {
        if (v.isClick && v.id == 0) {
          this.applyStatusId = '0';
          //将页码回归第一页
          this.currentPage = 1
          await this.async_getList();
        } else if (v.isClick && v.id == 1) {
          this.applyStatusId = v.id;
          //将页码回归第一页
          this.currentPage = 1
          await this.async_getList();
        } else if (v.isClick && v.id == 2) {
          this.applyStatusId = v.id;
          //将页码回归第一页
          this.currentPage = 1
          await this.async_getList();
        }
      });
    },
    async async_getList() { //获取列表数据
      let obj = {
        approvalStatus: this.applyStatusId||0,
        pageSize: this.size,
        pageNo: this.currentPage
      }
      let res1 = await API.TASK.msgList(obj);
      let { data } = res1;
      this.total = data.totalCount;
      this.dataList = data.data;
    },
  },
  async created() {
    await this.async_getList();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .out-content {
    .el-dialog {
      margin-top: 189px !important;
      height: 600px;
    }
  }
}

.taskDialong {
  width: 100%;

  .taskDraw {
    top: 32px !important;
    height: calc(100% - 32px) !important;
  }

  ::v-deep {
    .el-dialog__body {
      height: 500px !important;
    }
  }
}
.DemandList_Box {
  padding: 4px 24px;

  .left_box_top {
    display: flex;
    margin-bottom: 18px;

    .item_box {
      width: 86px;
      height: 32px;
      background: rgba(0, 0, 0, 0.7);
      font-size: 14px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: rgba(148, 179, 194, 0.7);
      line-height: 32px;
      text-align: center;
      cursor: pointer;
    }

    .isSelect {
      background: #1f4dcb;
      color: #ffffff;
    }
  }

  .DemandList_Body {
    display: flex;
    flex-wrap: wrap;

    .Body_Item {
      position: relative;
      margin-right: 90px;
      margin-bottom: 32px;
      width: 370px;
      height: 142px;
      // background: rgba(40,53,122,0.1);
      background: url("~@/assets/home/Box-Border.png") no-repeat;
      // border: 1px solid #73A6D1;
      backdrop-filter: blur(2px);
      padding: 18px;

      .Item_Label_Box {
        .Label_Box_Value {
          width: 184px;
          margin-bottom: 14px;
          display: flex;
          justify-content: space-between;

          .Box_Value_Left {
            width: 84px;
            height: 20px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #AAB7C3;
            line-height: 20px;
          }

          .Box_Value_Right {
            width: 84px;
            height: 20px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            white-space: nowrap;

            &.onPules {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .Item_Top {
          position: absolute;
          right: 123px;
          top: 23px;
          width: 66px;
          height: 66px;
          // background: #FF0000;
          background: url("~@/assets/home/ToBeApproved.png") no-repeat;
          opacity: 0.75;

          &.Approved {
            background: url("~@/assets/home/Approved.png") no-repeat;
          }

          &.ApprovalFailed {
            background: url("~@/assets/home/ApprovalFailed.png") no-repeat;
          }
        }

        .Item_Top_Right {
          cursor: pointer;
          position: absolute;
          right: 16px;
          top: 18px;
          width: 86px;
          height: 44px;
          background: rgba(38, 71, 238, 0.71);
          box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 1, 35, 0.5);
          border: 1px solid #fff;
          border-radius: 6px;
          border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: #FFFFFF;
          text-align: center;
          line-height: 42px;
        }
      }

      &:nth-child(3n) {
        //每一行第三个取消外边距
        margin-right: 0;
      }
    }
  }

  .DemandList_Footer {
    //列表底部
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .cpt-table-pagination {
      //右下分页组件样式
      height: 30px;
      display: flex;
      align-items: center;

      ::v-deep {
        button {
          background: transparent;
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          border-radius: 4px;
          line-height: 40px;
          margin-right: 8px;
          box-sizing: border-box;
          color: #b6d4ff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;

          &:disabled {
            background: transparent;
            color: #b6d4ff;
            opacity: 0.3;
          }
        }

        .el-pagination__total {
          font-size: 18px;
          color: #b6d4ff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          text-align: center;
          height: 40px;
          line-height: 40px;
          display: inline-block;
          margin: 0px;
        }

        .el-pager {
          height: 30px;

          .number {
            background: transparent;
            font-size: 12px;
            color: #b6d4ff;
            text-align: center;
            font-weight: lighter;
            width: 25px;
            height: 25px;
            box-sizing: border-box;
            // border: 1px solid #488cff;
            font-size: 18px;
            letter-spacing: 0;
            text-align: center;
            border-radius: 4px;
            line-height: 25px;
            margin-right: 8px;
            box-sizing: border-box;

            &.active {
              opacity: 0.8;
              // background-image: linear-gradient(
              //   180deg,
              //   rgba(44, 123, 255, 0.5) 0%,
              //   rgba(72, 140, 255, 0) 100%
              // );
              background-color: #2647ee;
              color: #ffffff;
            }
          }

          .more {
            background: transparent;
            line-height: 40px;
            width: 40px;
            height: 40px;

            box-sizing: border-box;
            // border: 1px solid #629fff;
            border-radius: 4px;
            margin-right: 8px;
            font-size: 20px;
            color: #b6d4ff;
          }
        }

        .el-pagination__jump {
          font-size: 18px;
          color: #b6d4ff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          width: 150px;
          height: 40px;
          margin-left: 6px;
          line-height: 40px;
          display: flex;
          align-items: center;

          .el-input {
            box-sizing: border-box;
            height: 100%;
            width: 84px;

            .el-input__inner {
              width: 60px;
              height: 25px;
              border-radius: 2px;
              border: 1px solid #b6d4ff;
              margin-top: 8px;
              // border: 1px solid #629fff;
              border-radius: 4px;
              background: transparent;
              // height: 100%;
              box-sizing: border-box;
              padding: 0;
              font-size: 18px;
              color: #90b2ec;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }
          }
        }
      }

      &.mini {
        height: 100%;
        display: flex;
        align-items: center;

        ::v-deep {
          button {
            height: 20px;
            width: 20px;
            line-height: 20px;
            background: transparent;
            border: none;
            color: #4d7592;
            min-width: auto;

            &.btn-next {
              margin-left: 8px;
            }
          }

          .el-pagination__total {
            font-size: 14px;
            color: #4d7592;
          }

          .el-pagination__sizes {
            display: none;
          }

          .el-pager {
            height: 30px;

            .number {
              height: 100%;
              width: auto;
              line-height: 20px;
              background: transparent;
              border: none;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
              min-width: 20px;
              margin-right: 0;

              &.active {
                opacity: 1;
                color: #0099ff;
              }
            }

            .more {
              height: 100%;
              width: 20px;
              line-height: 20px;
              font-size: 12px;
              border: none;
              min-width: auto;
              margin-right: 0px;

              &::before {
                line-height: 20px;
              }
            }
          }

          .el-pagination__jump {
            height: 100%;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;

            .el-input {
              .el-input__inner {
                border: 1px solid #4d7592;
                border-radius: 2px;
                width: auto;
                min-width: 20px;
                font-size: 14px;
                color: #4d7592;
                letter-spacing: 0;
                text-align: center;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}

/* 详情单样式 */
.dispatch {
  // height: 456px;
  width: 100%;

  // overflow: auto;
  .need-task-type {
    width: 300px;
    color: rgb(170, 183, 195);

    ::v-deep {
      .el-radio__label {
        color: #fff;
      }
    }
  }

  .need-top-title {
    height: 34px;

    .left {
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      padding-left: 10px;
      position: relative;

      .dec {
        position: absolute;
        top: 2px;
        left: 0;
        width: 4px;
        height: 16px;
        background: #FFBD36;
        border-radius: 2px;
      }
    }

    .right {
      font-size: 14px;
      color: #AAB7C3;
    }
  }

  .need-bottom {
    margin-top: 28px;
    font-size: 14px;
    color: #AAB7C3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .info-add {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    .one-info {
      width: 33.3%;
      margin-bottom: 12px;
    }

    .one-info-long {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .detail {
    height: 107px;
    margin-top: 12px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    padding: 16px 24px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .button {
    margin-top: 40px;
    padding: 0 140px;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;

    .no {
      cursor: pointer;
      width: 126px;
      height: 40px;
      // background: rgba(219,27,27,0.71);
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-right: 20px;
    }

    .yes {
      cursor: pointer;
      width: 126px;
      height: 40px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-left: 20px;
    }
  }
}

.Approved {
  .Approved_Body {
    width: 100%;

    &.FailedTextarea {
      margin-bottom: 20px;
    }

    ::v-deep {
      .el-textarea {
        .el-textarea__inner {
          background-color: rgba(0, 0, 0, 0.6);
          ;
          border: none;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }

    .Approved_Body_Item {
      width: 100%;
      text-align: center;
      margin-bottom: 21px;

      ::v-deep {
        .el-radio {
          color: #fff;
        }

        .el-radio__input.is-checked .el-radio__inner {
          border-color: #00D2FF;
          background: #00D2FF;
        }

        .el-radio__input.is-checked+.el-radio__label {
          color: #00D2FF;
        }
      }

      .iconApproved {
        color: #6AFF64;
      }

      .Item_Label {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 12px;
      }

      .Item_Label_Select {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  .Approved_Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-align: center;

    .no {
      cursor: pointer;
      width: 126px;
      height: 40px;
      // background: rgba(219,27,27,0.71);
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-right: 20px;
    }

    .yes {
      cursor: pointer;
      width: 126px;
      height: 40px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-left: 20px;
    }
  }
}
</style>